<template>
   
        <div class="yj-conten" style="height:100%; background-color:#fff;width:100%;min-width:fit-content;width:calc(100% - 32px);min-height: calc(100vh - 130px);">
            <a-row justify="space-between" style="background: #fff; padding:20px">
                <a-col>
                    <a-radio-group default-value="b" @change="onRadioChange">
                        <a-radio-button value="a">
                            基本信息
                        </a-radio-button>
                        <a-radio-button value="b">
                            门店地址
                        </a-radio-button>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-layout-content>
                <div class="yj-conten" style="background:#fff;">
                    <a-row class="yj-form">

                        <a-col :span="24">
                            <label class="yj-form_label">名称 :</label>
                            <a-input id="name"
                                     contextmenu="2455"
                                     :value="name"
                                     style="width:260px"
                                     @change="name_change"
                                     placeholder="名称"></a-input>
                            <a-button type="primary" @click="search">查询</a-button>
                            <a-button type="primary" style="float:right" @click="add();">新建地址</a-button>
                        </a-col>

                    </a-row>
                    <!--:customRow="handleClickRow"-->
                    <a-table :columns="columns"
                             rowKey="ID"
                             :data-source="tableData"
                             :pagination="pagination"
                             @change="pagerChange"
                             style="min-width:min-content"
                             class="yj-table-img">

                        <template slot="operation" slot-scope="text, record">

                            <a href="#" @click="onEdit(record.ID)" style="margin-right:20px;">编辑</a>

                            <a href="#" @click="onDelete(record.ID)">删除</a>

                        </template>
                    </a-table>
                </div>
            </a-layout-content>
        </div>
 
</template>
<style type="text/css">
  

    #title {
        height: 200px;
    }
</style>
<script>
    import http from "@/Plugin/Http.js";

    import { message } from 'ant-design-vue';
    // import { message } from 'ant-design-vue'

    export default {
        name: "SiteList",
        data() {
            return {
                columns: [
                    {
                        title: "名称",
                        dataIndex: "Name",
                        width: 150,
                        ellipsis: true,
                        align: "center"
                    },
                    {
                        title: "地址",
                        dataIndex: "Address",
                        width: 250
                    },
                    {
                        title: "联系人",
                        dataIndex: "LinkMan",
                        width: 200
                    },
                    {
                        title: "电话",
                        dataIndex: "Phone",
                        width: 200
                    },
                    {
                        title: "操作",
                        dataIndex: "operation",
                        scopedSlots: { customRender: 'operation' },
                        width: 300
                    }
                ],
                modelDisplay: false,        
                tableData: [],
                id: "",
                title: "",
                brief: "",
                name:"",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                }
            }
        },
        methods: {
            onRadioChange: function (data) {
                var self = this;
                var val = data.target.value;
                console.log(data.target);
                if (val == 'a') {
                    self.$router.push({ name: "EditBaseInfor" });
                } 
                //ShopManage_SiteList
                //EditBaseInfor
                //console.log(data);
            },
            add: function () {
                var self = this;
                self.$router.push({
                    name: "ShopManage_SiteDetail", query: {
                       
                    }
                })
            },
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            pagerChange: function (item) {
                var self = this;

                self.pagination.current = item.current;
                self.getTableData();
            },

            onEdit: function (data) {
            
                var self = this;            
                self.$router.push({
                    name: "ShopManage_SiteDetail", query: {
                        ID: data
                    }
                })
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            onDelete: function (data) {

                var self = this;
                self.$confirm({
                    title: '确定删除该报名点吗',
                    content: '',
                    okText: '是',
                    okType: 'danger',
                    cancelText: '否',
                    onOk() {
                        self.deleteQuestionDetail(data);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },

            deleteQuestionDetail: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/DelShopSite",
                    data: {
                        id: id,
                    },
                    OnSuccess: function (data) {
                        console.log(1234);
                        console.log(data);
                        if (data.data == true) {
                            message.success(
                                '删除成功',
                                1.5,
                                function () {
                                    self.getTableData();
                                    //self.$router.push({
                                    //    name: "ShopManage_ShopManage", query: {
                                    //        showName: "SiteList"
                                    //    }
                                    //})


                                }

                            );
                        }
                    }
                };
                http.Post(op);
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetSiteList",
                    data: {
                        name: self.name,
                        pageIndex: self.pagination.current,                       
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.PageCount;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            }
        },
        mounted() {
            this.getTableData();
        },
        computed: {}

    };
</script>